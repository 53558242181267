import React from 'react';
import {Navigation} from './helpers/Navigation';

function App() {

  return (
    <Navigation></Navigation>
  );
}

export default App;
